/*global $*/
/*eslint no-undef: "error"*/

import Swiper from 'swiper'
import {Autoplay, EffectCoverflow, Navigation, Pagination, Thumbs} from 'swiper/modules'
Swiper.use([Navigation, Pagination, EffectCoverflow, Autoplay, Thumbs])

export default class SwiperSlider {

    createSlider () {
        return new Swiper('.slider--slider', {
            slidesPerView: 3,
            spaceBetween: 15,
            // loop: true,
            navigation: {
                nextEl: '.slider--slider-next',
                prevEl: '.slider--slider-prev',
            },
            pagination: {
                el: '.swiper-pagination--slider',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                360: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                375: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                414: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                480: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                },
                514: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                },
                640: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                },
                991: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                },
                1240: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                },
                1440: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                }
            }
        })
    }
    createReview () {
        return new Swiper('.slider--review', {
            slidesPerView: 2,
            spaceBetween: 20,
            // loop: true,
            navigation: {
                nextEl: '.slider--review-next',
                prevEl: '.slider--review-prev',
            },
            pagination: {
                el: '.swiper-pagination--review',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                360: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                375: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                414: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                480: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                514: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                640: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                768: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                991: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                1024: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                1240: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                1440: {
                    slidesPerView: 2,
                    spaceBetween: 20
                }
            }
        })
    }
    createReviewClient () {
        return new Swiper('.slider--review-client', {
            slidesPerView: 2,
            spaceBetween: 15,
            // loop: true,
            navigation: {
                nextEl: '.slider--review-client-next',
                prevEl: '.slider--review-client-prev',
            },
            pagination: {
                el: '.swiper-pagination--review-client',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                360: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                375: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                414: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                480: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                514: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                640: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                768: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                991: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                1024: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                1240: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                },
                1440: {
                    slidesPerView: 2,
                    spaceBetween: 15
                }
            }
        })
    }
    createPortfolio () {
        return new Swiper('.slider--portfolio', {
            slidesPerView: 3,
            spaceBetween: 15,
            // loop: true,
            navigation: {
                nextEl: '.slider--portfolio-next',
                prevEl: '.slider--portfolio-prev',
            },
            pagination: {
                el: '.swiper-pagination--portfolio',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                320: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                360: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                375: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                414: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                480: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                },
                514: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                },
                640: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                },
                991: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                },
                1240: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                },
                1440: {
                    slidesPerView: 3,
                    spaceBetween: 15
                }
            }
        })
    }
    createSliderList () {
        return new Swiper('.slider--list', {
            slidesPerView: 4,
            spaceBetween: 15,
            // loop: true,
            navigation: {
                nextEl: '.slider--list-next',
                prevEl: '.slider--list-prev',
            },
            pagination: {
                el: '.swiper-pagination--list',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                320: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                360: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                375: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                414: {
                    slidesPerView: 4,
                    spaceBetween: 10,
                },
                480: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                },
                514: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                },
                640: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                },
                991: {
                    slidesPerView: 4,
                    spaceBetween: 15,
                },
                1024: {
                    slidesPerView: 4,
                    spaceBetween: 15,
                },
                1240: {
                    slidesPerView: 4,
                    spaceBetween: 15,
                },
                1440: {
                    slidesPerView: 4,
                    spaceBetween: 15
                }
            }
        })
    }
    createPartner () {
        return new Swiper('.slider--partner', {
            slidesPerView: 5,
            spaceBetween: 10,
            autoplay: {
                delay: 4500
            },
            // loop: true,
            navigation: {
                nextEl: '.slider--partner-next',
                prevEl: '.slider--partner-prev',
            },
            pagination: {
                el: '.swiper-pagination--partner',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 2,
                    spaceBetween: 5,
                },
                320: {
                    slidesPerView: 2,
                    spaceBetween: 5,
                },
                360: {
                    slidesPerView: 2,
                    spaceBetween: 5,
                },
                375: {
                    slidesPerView: 2,
                    spaceBetween: 5,
                },
                414: {
                    slidesPerView: 2,
                    spaceBetween: 5,
                },
                480: {
                    slidesPerView: 3,
                    spaceBetween: 5,
                },
                514: {
                    slidesPerView: 3,
                    spaceBetween: 5,
                },
                640: {
                    slidesPerView: 3,
                    spaceBetween: 5,
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 5,
                },
                991: {
                    slidesPerView: 4,
                    spaceBetween: 10,
                },
                1024: {
                    slidesPerView: 4,
                    spaceBetween: 10,
                },
                1240: {
                    slidesPerView: 5,
                    spaceBetween: 10,
                },
                1440: {
                    slidesPerView: 5,
                    spaceBetween: 10
                }
            }
        })
    }

    constructor () {
        if (parseInt($('.slider--slider').length) > 0) {
            this.createSlider()
        }

        if (parseInt($('.slider--review').length) > 0) {
            this.createReview()
        }

        if (parseInt($('.slider--review-client').length) > 0) {
            this.createReviewClient()
        }

        if (parseInt($('.slider--portfolio').length) > 0) {
            this.createPortfolio()
        }

        if (parseInt($('.slider--list').length) > 0) {
            this.createSliderList()
        }

        if (parseInt($('.slider--partner').length) > 0) {
            this.createPartner()
        }
    }
}
